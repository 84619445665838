@font-face {
font-family: 'Roboto Light';
src:
  url("/portal/fonts/Roboto-Light.woff2") format('woff2'),
  url("/portal/fonts/Roboto-Light.woff") format('woff');
font-weight: 300;
font-style: normal;
font-display: swap;
}

@font-face {
font-family: 'Roboto';
src:
  url("/portal/fonts/Roboto-Regular.woff2") format('woff2'),
  url("/portal/fonts/Roboto-Regular.woff") format('woff');
font-weight: 400;
font-style: normal;
font-display: swap;
}

@font-face {
font-family: 'Roboto Medium';
src:
  url("/portal/fonts/Roboto-Medium.woff2") format('woff2'),
  url("/portal/fonts/Roboto-Medium.woff") format('woff');
font-weight: 500;
font-style: normal;
font-display: swap;
}

@font-face {
font-family: 'Roboto Bold';
src:
  url("/portal/fonts/Roboto-Bold.woff2") format("woff2"),
  url("/portal/fonts/Roboto-Bold.woff") format("woff");
font-weight: 700;
font-style: normal;
font-display: swap;
}
