
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@use '../style/container.scss';
@import '../style/globalColorVars.scss';
@import '../style/font-family.scss';
// @import '../style/form-defaults.scss';


html,
body {
 height: 100%;
}

body > :first-child {
 height: 100%;
 min-height: 100%;
}


.portal-container {
  margin: 0 auto 30px auto;
  max-width: 1440px;
  width: 92%;
}

// This color doesn't appear in any of our var sheets
// we need to update and add.

.portal-app {
 // sticky footer
 display: flex;
 flex-direction: column;
 height: 100%;

 // sticky footer
 .content-wrap {
  flex: 1 0 auto;
  margin-bottom: 100px;
 }

 .portal-footer {
  background: $background-dark-grey;
  flex-shrink: 0;
 }

 // This is a hack  - our imported searchbar svg icons
 // are not picking up any styles as it does on player page despite this being
 // a shared component.
 // Adding rules to work for our svg classname here until we can see why a
 // shared component behaves differently between apps. Aud team
 button {

  svg {
   width: 1em;
   vertical-align: -0.125em;
  }
 }
}

.footer {
 @include roboto-bold;
}

// 500 page styles
.internal-server-500 {
 display: flex;
 flex-wrap: wrap;
 justify-content: center;

 .content-wrap {
  flex: 0 0 42%;
  padding: 30px;
  line-height: 22px;

  .heading{
   font-size: 36px;
   margin-bottom: 20px;
  }

  .content{
   @include roboto-light;
  }

  .contact-us {
   color: $button-primary-color;
   @include roboto-regular;
  }
 }
}
