
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@use './makePageFluid.scss';
@use './makeRelative.scss';
@use './globalVars.scss';
@import './globalColorVars.scss';
@import './font-family.scss';

html {
  @include makePageFluid.fluid-type(
    globalVars.$min_width,
    globalVars.$max_width,
    globalVars.$min_font,
    globalVars.$max_font
  );
}

main {
  position: relative;
  top: -30px;

  @media screen and (min-width: 887px) {
    top: 0;
  }
}

body {
  margin: 0;
  padding: 0;
  background-color: $background-default;
  line-height: globalVars.$stdLineHeight;
}

body,
input {
  color: $primary-text;
  -webkit-font-smoothing: subpixel-antialiased;
}

header,
div,
input,
h2 {
  box-sizing: border-box;
}

ul,
p {
  @include roboto-light;
  color: $primary-text;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
  text-indent: unset;
  padding: 0;
}

a,
a:hover,
a:focus {
  text-decoration: none;
  color: $link-primary;
}

h2 {
  @include makeRelative.fontSize(26px);
  color: $primary-text;
}

h3 {
  @include makeRelative.fontSize(16px);
  padding-bottom: 10px;
  margin: 0;
}

body {
  @include roboto-regular;
}

h1,
h2 {
  @include roboto-medium;
}

button {
  @include roboto-regular;
}

a {
  @include roboto-regular;
  &:hover {
    color: $button-primary-hover-color;
  }
  &:focus,
  &:active {
    color: $button-primary-focus-color;
  }
}

h3,
b,
strong,
em {
  @include roboto-bold;
}

main {
  padding-top: 4px;
}

header {
  margin-bottom: 30px;
}

.sr-only-streched {
  color: red !important;
}

// /* ---- SectionHeadings margin styling -----*/

// Until we can find a programtic way to get back our first grid on page class
// These styles have to style based on sibling selectors.
// Styles are here as element classes are in component index.module.scss files
// & we can't target styles across these files.

// .carousel-parent represents  the filter buttons IF they are present
// on a page & are the element BEFORE the talk grids
.carousel-parent + .grid-box {
  > .generic-section-heading {
    margin-top: 0;
  }
}

// series & summits pages with featured talk box
.generic-featured + .grid-box {
  > .generic-section-heading {
    margin-top: 60px;
  }
}
// Skeleton sectionheadings MAY NOT have a grid so don't have a grid block wrapper
.carousel-parent + .generic-section-heading {
  margin-top: 0;
}

.container {
  > .generic-section-heading:first-child {
    margin-top: 0;
  }
}
// Pages that have a generic hero banner area
.container > .grid-box:first-child {
  > .generic-section-heading {
    margin-top: 0;
  }
}

// Homepage has slightly diffetent layout no styles / margin bottom for
// hero banner so add the 30px to sectionHeading not remove.
.container-homepage > .grid-box:first-child {
  > .generic-section-heading {
    margin-top: 30px;
  }
}

// homepage skeleton loads without a parent .grid-box so we need additional rule
.container-homepage.container > .generic-section-heading {
  margin-top: 30px;
}

// mybrighttalk section headings when there are no grids

.bt-section-headings.mybrighttalk-no-grid-heading {
  margin-top: 0;
}

div.mybrighttalk-nav-tabs + .latest-talk-block {
  .generic-section-heading:first-child {
    margin-top: 0;
  }
}

div.mybrighttalk-nav-tabs + .generic-section-heading,
div.mybrighttalk-nav-tabs + div > .generic-section-heading {
  margin-top: 0;
}

// Single channel page layout container (div)
.single-channel-content {
  margin: 0 auto;
  padding: 0 12px;
  width: 92%;
  max-width: 1440px;
}
